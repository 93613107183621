* {
  margin: 0;
  padding: 0;
}

.header {
  padding: 0 32px;
  height: 85px;
  background: #fefefe;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  position: relative;
}

.nav-menu {
  display: flex;
  position: absolute;
  justify-content: center;
  font-family: 'Noto Sans';
  opacity: 0.8;
  width: 100%;
}

.nav-menu-item {
  margin: 0 12px;
  font-size: 18px;
}

.nav-menu-item:hover {
  color: #1590cf;
  transition: all 0.2s;
}

.nav-menu-item:hover {
  cursor: pointer;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo-section img {
  width: 165px;
}

.logo-section p {
  margin-left: 16px;
  font-size: 22px;
}

.header_section {
  position: relative;
}

#header_section_info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center {
  display: flex;
  justify-content: center;
}

#header-section-title {
  color: white;
  margin-bottom: 42px;
  text-align: center;
  font-size: 24px;
}

#header-section-title p {
  margin-top: 16px;
  font-size: 20px;
}

.activities {
  display: flex;
  justify-content: center;
}

.activity-circle:nth-child(2) {
  margin: 0 32px;
}

#read_more {
  margin-top: 32px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
}

#read_more:hover {
  cursor: pointer;
}

.activity-circle {
  border: 1px solid white;
  border-radius: 100px;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .activity-circle {
    width: 110px;
    height: 110px;
  }

  .activity-circle:nth-child(2) {
    margin: 0 12px;
  }

  .activity-circle p {
    color: white;
    font-size: 13px;
  }
}

.activity-circle p {
  color: white;
  font-size: 16px;
}

.btn {
  width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 5px;
  background: #01d3f4;
  font-size: 18px;
  color: white;
  font-weight: bold;
}

#header_image {
  background-image: url('./background.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 552px;
  width: 100%;
  overflow: hidden;
}

.blur {
  background: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(5px);
  height: 100vh;
}

#header_image::before {
  content: '';
  display: block;
  -webkit-filter: blur(1px) brightness(0.5);
  -moz-filter: blur(1px) brightness(0.5);
  -ms-filter: blur(1px) brightness(0.5);
  -o-filter: blur(1px) brightness(0.5);
  filter: blur(1px) brightness(0.5);
  position: absolute;
  left: 0px;
  top: 0.1px;
  right: 0px;
  bottom: 0px;
  background: inherit;
  z-index: 0;
}

.section {
  padding: 32px;
}

#footer {
  background-color: #2C2C2C;
  height: fit-content;
  width: 100%;
}



.mb-12 {
  margin-bottom: 12px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-32 {
  margin-bottom: 32px;
}