.calendar-event {
  margin-bottom: 16px;
	white-space: pre-line;
	list-style: none;
}

.calendar-event p:nth-child(2) {
	margin-top: 4px;
}

#calendar {
	display: flex;
	flex-wrap: wrap;
}

#calendar .calendar-section:nth-child(1) {
	margin-right: 32px;
}

#calendar .calendar-section:nth-child(2) {
	margin-right: 32px;
}

.calendar-section {
	margin-bottom: 16px;;
}

#calendar_section {
	margin: 0 auto;
	max-width: 1150px;
}