
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Noto+Sans:wght@400;700&family=Nova+Oval&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  list-style: none;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

