.bar1,
.bar2 {
  width: 24px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.bar2 {
  width: 20px;
}

.fold .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-3px, 6px);
}

.fold .bar2 {
  -webkit-transform: rotate(45deg) translate(-4px, -4px);
  width: 24px;
  transform: rotate(45deg) translate(-1px, -4px);
}

.menu-burger:hover {
  cursor: pointer;
}
